const ENV = process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.NODE_ENV || 'development'

export const Cookie = {
  get(cname = '') {
    const name = cname + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]

      while (c.charAt(0) == ' ') c = c.substring(1)
      if (c.indexOf(name) != -1) {
        let cookie = c.substring(name.length, c.length)
        cookie = decodeURIComponent(cookie)

        return cookie
      }
    }
    return null
  },
  set(cname = '', cvalue = '', exdays = 1, minutes = 0) {
    let value = JSON.stringify(cvalue)
    value = JSON.parse(value)

    const d = new Date()
    const time = minutes ? minutes * 60 * 1000 : exdays * 24 * 60 * 60 * 1000

    d.setTime(d.getTime() + time)

    const expires = 'expires=' + d.toUTCString()

    value = encodeURIComponent(value)

    let cookie = cname + '=' + value + ';path=/;' + expires
    if (ENV === 'production') {
      cookie += ';domain=' + '.saludia.com.br'
    }
    document.cookie = cookie
  },
  delete(cname = '') {
    this.set(cname, '', -10)
  },
}

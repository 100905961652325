import { Cookies } from '@/enums/cookies'
import { Cookie } from '@/lib/cookies'
import { IUTMs } from '@/interfaces/utms'
import { ParsedUrlQuery } from 'querystring'
import { LS_PATIENT_CPF } from './localStorage'

export const identifyUser = (cpf?: string, traits?: any) => {
  const UTMs = JSON.parse(Cookie.get(Cookies.UTMS) || '{}')
  const cpfLocalStorage = localStorage.getItem(LS_PATIENT_CPF)
  const id = cpf || cpfLocalStorage

  if (id) {
    window.rudderanalytics?.identify(id, { ...traits, ...UTMs })
  } else if (Object.keys(UTMs).length) {
    window.rudderanalytics?.identify({ ...traits, ...UTMs })
  }
}

export const trackEvent = (eventName: string, properties?: any) => {
  if (properties) {
    window.rudderanalytics?.track(eventName, properties)
  } else {
    window.rudderanalytics?.track(eventName)
  }
}

export const trackPageView = () => {
  window.rudderanalytics?.page()
}

export const setUtmCookies = (url: ParsedUrlQuery) => {
  const utms = {} as IUTMs
  const { utm_medium, utm_source, utm_campaign, utm_content, utm_term } = url

  if (utm_medium) utms.utm_medium = utm_medium
  if (utm_source) utms.utm_source = utm_source
  if (utm_campaign) utms.utm_campaign = utm_campaign
  if (utm_content) utms.utm_content = utm_content
  if (utm_term) utms.utm_term = utm_term

  if (Object.keys(utms).length) Cookie.set(Cookies.UTMS, JSON.stringify(utms))
}

export enum ErrorPagesPathname {
  NameSelectionErrorPage = '/erro-agendamento/erro-validacao',
  RepeatedAppointmentErrorPage = '/erro-agendamento/agendamento-repetido',
  HealthInsuranceAuthorizationErrorPage = '/erro-agendamento/autorizacao-convenio',
  AgeOutsideAllowedRangeErrorPage = '/erro-agendamento/idade-fora-do-intervalo-permitido',
  AppointmentError = '/erro-agendamento',
}

export const ErrorPageConfig: Record<
  ErrorPagesPathname,
  { labelErrorEvent: string; subtitleErrorPage: string }
> = {
  [ErrorPagesPathname.NameSelectionErrorPage]: {
    labelErrorEvent: 'selecionou nome incorreto',
    subtitleErrorPage:
      'Infelizmente não foi possível validar seus dados junto ao convênio. Por favor, insira novamente o nome e nome do paciente para prosseguir com o seu agendamento.',
  },
  [ErrorPagesPathname.RepeatedAppointmentErrorPage]: {
    labelErrorEvent:
      'usuário tentou realizar mais de um agendamento com o mesmo profissional na mesma data',
    subtitleErrorPage: '',
  },
  [ErrorPagesPathname.HealthInsuranceAuthorizationErrorPage]: {
    labelErrorEvent:
      'usuário com idade fora do intervalo permitido clicou no nome correto',
    subtitleErrorPage: '',
  },
  [ErrorPagesPathname.AgeOutsideAllowedRangeErrorPage]: {
    labelErrorEvent:
      'usuário com idade fora do intervalo permitido clicou no nome correto',
    subtitleErrorPage: '',
  },
  [ErrorPagesPathname.AppointmentError]: {
    labelErrorEvent: 'erro genérico',
    subtitleErrorPage: '',
  },
}

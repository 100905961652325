export enum UrlsOperadoras {
  PortoSeguro = 'https://portoseguro.saludia.com.br',
  FundacaoSaudeItau = 'https://fundacaosaudeitau.saludia.com.br',
  Omint = 'https://agendamentos.saludia.com.br',
  KippSaude = 'https://kippsaude.saludia.com.br',
  CarePlus = 'https://careplus.saludia.com.br',
  Alice = 'https://alice.saludia.com.br',
  Sami = 'https://sami.saludia.com.br',
  Abas = 'https://abas.saludia.com.br',
  Main = 'https://saludia.com.br',
  SegurosUnimed = 'https://segurosunimed.saludia.com.br',
  SulAmerica = 'https://sulamerica.saludia.com.br',
}
